import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Title from 'components/Elemnts/Title';
// import Email from 'components/Email/Email';
import IconTel from 'assets/images/IconTel.svg';
import IconMail from 'assets/images/IconMaiil.svg';
import Button from 'components/Elemnts/Button';

const Titles = styled(Title)`
  font-size: ${({ theme }) => theme.font.size.l};
`;

const StyleWrapper = styled.div`
  width: 100%;
  height: auto;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    padding: 0;
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  ${({ theme }) => theme.media.large} {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    padding: 0 2vw;
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const StyledInner = styled.div`
  width: 90%;
  max-width: 850px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    min-height: 300px;
    max-width: 1450px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    margin-top: 10px;
  }
  ${({ theme }) => theme.media.large} {
    flex-direction: row;
    width: 80%;
    min-height: 270px;
    height: auto;
    display: flex;
    justify-content: center;
    align-content: flex-start;
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${({ theme }) => theme.media.tablet} {
  width:100%;
  display:flex;
  flex-direction:row;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 82%;
    height: auto;

    min-height:20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  ${({ theme }) => theme.media.large} {
    width: 75%;
    height: auto;
    max-height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const NameDiv = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  ${({ theme }) => theme.media.desktop} {
    &:nth-child(2) {
      margin-bottom: 0px;
    }
  }
`;

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: 600;
`;

const Addres = styled.div`
  width: 80%;
  height: 75px;
  margin: 2px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    align-items: center;
    margin-bottom: 10px;
  }
`;
const AddresP = styled.p`
  height: 23px;
  margin: 2px 0;
  font-size: ${({ theme }) => theme.font.size.xm};
  font-weight: 500;
`;

const NumberAP = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerH1 = styled.h1`
  padding: 10px;
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: 600;
`;

const Contacts = styled.div`
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  ${({ theme }) => theme.media.desktop} {
    margin: 0;
    width: 100%;
    margin-top: px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const ContactsP = styled.p`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: 600;
  text-decoration: none;
  height: 25px;
  margin: 2px 0;
`;
const StyledRightSide = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.tablet} {
    width:50%;
    border-left: 2px ${({ theme }) => theme.primary} solid;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    min-height: 275px;

    margin-left: 25px;
    border-left: 2px ${({ theme }) => theme.primary} solid;
  }
  ${({ theme }) => theme.media.large} {
    width: 40%;
    margin-left: 1vw;
  }
`;

const StyleAll = styled.div`
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    margin-left: 10px;
  }
`;
const StyledLeftSide = styled.div`
 width:100%;
${({ theme }) => theme.media.tablet} {
    width:50%;
  }
  ${({ theme }) => theme.media.desktop} {
    margin-left: 1%;
    width: 55%;
  }
`;

// const NamepunktDiv = styled.div`
//   width: 100%;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-top: 2px ${({ theme }) => theme.primary} solid;
//   padding-top: 25px;
//   margin-bottom: 20px;
//   ${({ theme }) => theme.media.desktop} {
//     border-top: none;
//     margin-bottom: 15px;
//   }
//   ${({ theme }) => theme.media.large} {
//     border-top: none;
//     margin-bottom: 5px;
//     padding-left: 5px;
//   }
// `;

const AP = styled.a`
  color: black;
  text-decoration: none;
`;

const StyledTitle = styled.div`
  width: 80%;
  margin-top: 5px;
  margin-left: 5%;
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    margin-top: 5px;
  }
  ${({ theme }) => theme.media.large} {
    width: 84%;
    height: auto;
    margin-top: 5px;
    margin-left: 0px;
  }
`;
const StyledContactOther = styled.div`
  width: 100%;
  height: auto;

    display:flex;
    flex-direction:column;
    align-items:center;


  ${({ theme }) => theme.media.desktop} {
    position: relative;
    width: 45%;
    height: 100%;
    margin-top:5px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-left: 2px ${({ theme }) => theme.primary} solid;
  }
  ${({ theme }) => theme.media.large} {
    position: relative;
    width: 45%;
    height: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const A = styled.a`
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledContactButton = styled.div`
width: 80%;
  height: auto;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
 ${({ theme }) => theme.media.large} {
  width: 80%;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  }

`;

const StyledButton = styled(Button)`
  width: 180px;
  height: 70px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  &.text {
    text-decoration: none;
    color: red;
  }
`;
const StyledTel = styled.div`
  display: flex;
  padding: 0 10px;
`;
const Tel = styled(IconTel)`
  width: 30px;
  height: 30px;
  margin: 0px 10px;
`;
const Icon = styled.img`
width: 30px;
height: 30px;
margin: 0 10px;


/* margin:  ${({ toUp }) => (toUp ? '0px 10px' : '15px 10px')}; */

`;

const Mail = styled(IconMail)`
  width: 30px;
  height: 30px;
  margin: 5px 5px 0 0;
`;

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsContact {
        logogmail {
          url
          title
          alt
        }
        logo {
          alt
          title
          url
        }
      }
      allDatoCmsContact {
        nodes {
          id
          name
          addres
          eMail
          numberAp
          originalId
          phone
          phoneMobile
          postCode
          title
          namepunkt
          adrespunkt
          postcodepunkt
          phonemobilepunkt
        }
      }
    }
  `);

  return (
    <StyleWrapper>
      <StyledTitle>
        <Titles>Kontakt</Titles>
      </StyledTitle>
      <StyledInner>
        {data.allDatoCmsContact.nodes.map(allDatoCmsContact => (
          <StyledDiv key={allDatoCmsContact.id}>
            <StyledLeftSide>
              <StyleAll>
                <NameDiv>
                  <H1>{allDatoCmsContact.name}</H1>
                </NameDiv>
                <NumberAP>
                  <InnerH1>{allDatoCmsContact.numberAp}</InnerH1>
                </NumberAP>

                <Contacts>
                  <Addres>
                    <AddresP>{allDatoCmsContact.addres}</AddresP>
                    <AddresP>{allDatoCmsContact.postCode}</AddresP>
                  </Addres>
                  <StyledTel>
                    <A href="tel:+48661914411">
                      <Tel />
                    </A>
                    <div>
                      <ContactsP>
                        <A href="tel:+48661914411">{allDatoCmsContact.phoneMobile}</A>
                      </ContactsP>
                    </div>
                  </StyledTel>
                  <ContactsP>
                    <A href="mailto:pasiekacywińskich@gmail.com">
                      <Mail />
                      {allDatoCmsContact.eMail}
                    </A>
                  </ContactsP>
                </Contacts>
              </StyleAll>
            </StyledLeftSide>
            <StyledRightSide>
              <NameDiv>
                <h2>{allDatoCmsContact.namepunkt}</h2>
              </NameDiv>
              <Contacts>
                <Addres>
                  <AddresP>{allDatoCmsContact.adrespunkt}</AddresP>
                  <AddresP>{allDatoCmsContact.postcodepunkt}</AddresP>
                </Addres>
                <StyledTel>
                  <A href="tel:+48797126644">
                    <Tel toUp />
                  </A>
                  <ContactsP>
                    <AP href="tel:+48797126644">{allDatoCmsContact.phonemobilepunkt}</AP>
                  </ContactsP>
                </StyledTel>
              </Contacts>
            </StyledRightSide>
          </StyledDiv>
        ))}

        <StyledContactOther>
          <h2>Możesz sie z nami skontaktować przez</h2>
          <StyledContactButton>
            <A href={data.datoCmsContact.logo.alt}>
              <StyledButton type="submit">
                <Icon src={data.datoCmsContact.logo.url} alt="icon" />
                {data.datoCmsContact.logo.title}
              </StyledButton>
            </A>

            <A href={data.datoCmsContact.logogmail.alt}>
              <StyledButton type="submit">
                <Icon src={data.datoCmsContact.logogmail.url} alt="icon" />
                {data.datoCmsContact.logogmail.title}
              </StyledButton>
            </A>
          </StyledContactButton>
        </StyledContactOther>
      </StyledInner>
    </StyleWrapper>
  );
};
export default Contact;

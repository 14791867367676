import React from "react"

import ContactSide from "components/Pages/ContactPage/ContactSide";



const ContactPage = () => (


  <>

    <ContactSide/>

  </>
)


export default ContactPage

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import IconLoc from 'assets/images/IconLoc.svg';
import Title from 'components/Elemnts/Title';
import Contact from 'components/Contact/Contact';

const StyleWrapper = styled.div`
  width: 100vw;
  height: auto;

  font-family: ${({ theme }) => theme.font.family.Montserrat};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    z-index:-20;
    margin-top: 50px;
  }
`;


const GoogleMaps = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::after {
  }
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;
const IFRAME = styled.iframe`
  width: 90%;
  height: 350px;
  border-radius: 10px;
  ${({ theme }) => theme.media.desktop} {
    width: 70%;
    height: 550px;
    border-radius: 10px;
  }
`;
const StyledTitles = styled.div`
  margin-top: 20px;
  width: 90%;

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    margin-left: 10%;

  }
`;

const Titles = styled(Title)`
  margin-top: 0px;
  width: 100%;
  height: auto;
  max-height: 50px;
  font-size: ${({ theme }) => theme.font.size.xs};
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const StyledButtons = styled.div`
  width: 90%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.desktop} {
    width: 30%;
    height: 550px;
    margin: 0;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px ${({ theme }) => theme.grey} solid;
    border-radius: 10px;
  }
`;
const A = styled.a`
  width: 90%;
  height: 100px;
  display: flex;
  color: black;
  text-decoration: none;
`;
// const AP = styled.a`
//   color: black;
//   text-decoration: none;
// `;

const Buttons = styled.button`
  width: 100%;
  height: 110px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 2px ${({ theme }) => theme.primary} solid;
  border-radius: 10px;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: 600;
  transition: all 0.2s 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.primary};
  }

  ${({ theme }) => theme.media.desktop} {
    width: 95%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.primary} ;
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    font-size: ${({ theme }) => theme.font.size.s};
    font-weight: 600;
    transition: all 0.2s 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      background-color: ${({ theme }) => theme.primary};
    }
  }
`;

const Loc = styled(IconLoc)`
  width: 45px;
  height: 45px;
  margin: 5px 0;
  text-align: none;
`;

const Div1 = styled.div`
  width: 20%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Div2 = styled.div`
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const P = styled.p`
  margin: 5px 0;
`;

const ContactSide = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsContact {
        headtitle
        name
        namepunkt
        adrespunkt
      }
    }
  `);

  return (
    <StyleWrapper>

      <GoogleMaps>
        <StyledButtons>
        <StyledTitles>
        <Titles>
          <h3>{data.datoCmsContact.headtitle}</h3>
        </Titles>
      </StyledTitles>
          <Buttons>
            <A href="https://goo.gl/maps/3WecRUGfqqvq5CxJA">
              <Div1>
                <Loc />
              </Div1>
              <Div2>
                <P>{data.datoCmsContact.name}</P>
                <P>Wiązowna ul. Lubeska 46b</P>
              </Div2>
            </A>
          </Buttons>

          <Buttons>
            <A href="https://goo.gl/maps/6qwddpkWPdrKs6Sf6">
              <Div1>
                <Loc />
              </Div1>
              <Div2>
                <P>{data.datoCmsContact.namepunkt}</P>
                <P>{data.datoCmsContact.adrespunkt}</P>
              </Div2>
            </A>
          </Buttons>
        </StyledButtons>

        <IFRAME title="map" src="https://www.google.com/maps/d/u/0/embed?mid=1xUv-1MA8jFuTe0NkAaZDTDKquIcS0GQZ" />
      </GoogleMaps>
      <Contact />
    </StyleWrapper>
  );
};
export default ContactSide;
